<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Resúmenes</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table :resource="resource" :columns="columns">
				<tr slot="heading">
					<th>N°</th>
					<th class="text-center">Fecha Emisión</th>
					<th class="text-center">Fecha Referencia</th>
					<th>Identificador</th>
					<th>Estado</th>
					<th>Ticket</th>
					<th class="text-center">Descargas</th>
					<th class="text-right">Acciones</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td class="text-center">{{ row.date_of_issue }}</td>
					<td class="text-center">{{ row.date_of_reference }}</td>
					<td>{{ row.identifier }}</td>
					<td>{{ row.state_type_description }}</td>
					<td>{{ row.ticket }}</td>
					<td class="text-right pl-0 pr-1">
						<v-btn
							color="success"
							class="mr-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_xml)"
							v-if="row.has_xml"
						>
							XML
						</v-btn>

						<v-btn
							color="info"
							class="ma-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_cdr)"
							v-if="row.has_cdr"
						>
							CDR
						</v-btn>
					</td>
					<td class="text-right">
						<v-btn
							color="info"
							class="ma-1"
							x-small
							dark
							@click.prevent="clickTicket(row.id)"
							v-if="row.btn_ticket"
						>
							Consultar
						</v-btn>
						<v-btn
							color="error"
							x-small
							dark
							@click.prevent="clickDelete(row.id)"
							v-if="row.btn_ticket"
						>
							Eliminar
						</v-btn>
					</td>
				</tr>
			</emb-data-table>
		</v-card>

		<summary-form :showDialog.sync="showDialog" :external="false"> </summary-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import SummaryForm from './Form';

export default {
	mixins: [deletable],
	components: { SummaryForm },
	data() {
		return {
			resource: 'summaries',
			showDialog: false,
			records: [],
			columns: {
				date_of_issue: 'Fecha de emisión',
			},
		};
	},
	methods: {
		clickCreate() {
			this.showDialog = true;
		},
		clickTicket(id) {
			this.$http
				.get(`/${this.resource}/status/${id}`)
				.then((response) => {
					if (response.data.success) {
						this.$eventHub.$emit('reloadData', this.resource);
						this.$message.success(response.data.message);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				});
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
		clickDownload(download) {
			window.open(download, '_blank');
		},
	},
};
</script>
